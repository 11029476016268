import Routes from '../routes.mjs';
import redirectToDomain from './redirectToDomain';

export const getTargetOrigin = (
  sourceOrigin,
  targetOriginSuffix = 'fashionunited.com',
) => sourceOrigin.replace(
  // eslint-disable-next-line max-len
  /(-(au|dashboard)(\.))?((au|dashboard)\.)?[^./]+\.((com\.[^.:]+)|([^.:]+))($|(?=:))/,
  `$3${targetOriginSuffix}`,
).replace(/\/\/review-([^.]+?)(-pwa)?\./, '//review-$1-pwa.');

const getPortServer = (request) => {
  if (request.get('x-forwarded-port')) {
    return `:${request.get('x-forwarded-port')}`;
  }

  return '';
};

const getPortBrowser = () => {
  if (window.location.port) { return `:${window.location.port}`; }
  return '';
};

const redirectRouteToDomain = (route, allowDashboard) => (
  { req, res, query },
) => {
  // eslint-disable-next-line no-underscore-dangle
  const locale = req ? req.locale : window.__NEXT_DATA__.props.locale;
  const path = Routes.findAndGetUrls(route, query).urls.as;

  const hostname = req ? req.get('host') : window.location.hostname;
  const port = req ? getPortServer(req) : getPortBrowser();
  const origin = `https://${hostname}${port}`;
  const targetOrigin = getTargetOrigin(origin);

  if (locale !== 'en-US' && hostname !== 'localhost' && !allowDashboard) {
    redirectToDomain(path, res, targetOrigin);
  }
  return {};
};

export default redirectRouteToDomain;
